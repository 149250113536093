$theme-colors: (
  "primary": #003661,
  "danger": #f35b3f,
  "warning": #ffc107,
  "orange": #ff7a00,
  "navy": #003661,
  "cyan": #05d9ff,
  "blue": #009cf0,
  "azure": #0073e8,
  "gray": #f2f2f2,
  "subdued-orange": #ffb83a,
  "dark": #292b2c,
  "light": #f7f7f7,
  "secondary": #868e96,
  "success": #28a745,
  "print-orange": #FFE4cc,
  "print-cyan": #CDF7FF,
  "print-blue-gray": #CCEBFC,
  "print-blue": #CCE3FA,
  "print-gray": #CCD7DF
);

@import "~bootstrap/scss/bootstrap.scss";

body {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Merriweather Sans', sans-serif !important;
  font-weight: 100;
  font-size: 100%;
  min-height: 100vh;
}

button {
  border-style: none;
}

li.no-bullet{
  list-style: none;
  margin-left: -1.25em;
}

.btn-orange{
  color: #f7f7f7;
}

body.waiting * {
    cursor: progress;
}
/**********  Bootstrap Forms ***********/

.rails-bootstrap-forms-date-select select,
.rails-bootstrap-forms-time-select select,
.rails-bootstrap-forms-datetime-select select {
  display: inline-block;
  width: auto;
  min-width: 8em;
}

.rails-bootstrap-forms-error-summary {
  margin-top: 10px;
}

/************************************/

/**********  Landing Page ***********/

header.masthead {
    padding-top: 10rem;
    padding-bottom: calc(10rem - 72px);
    background: linear-gradient(to bottom, rgba(92, 77, 66, 0.8) 0%, rgba(92, 77, 66, 0.8) 100%), url("../../assets/images/bg-writing.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    min-height: 100vh;
}

header.masthead h1 {
    font-size: 2.25rem;
}

@media (min-width: 992px) {
    header.masthead {
        height: 100vh;
        min-height: 40rem;
        padding-top: 72px;
        padding-bottom: 0;
    }
    header.masthead p {
        font-size: 1.15rem;
    }
    header.masthead h1 {
        font-size: 3rem;
    }
}

@media (min-width: 1200px) {
    header.masthead h1 {
        font-size: 3.5rem;
    }
}

#portfolio .container-fluid {
    max-width: 1920px;
}

#portfolio .container-fluid .portfolio-box {
    position: relative;
    display: block;
}

/************************************/

.container{max-width: 1600px;} 
.nav-link{color: black !important;} 
 
ul .positive{ 
  list-style: none; /* Remove default bullets */ 
} 
 
ul .positive::before { 
  content: "+";  /* Add content: \2022 is the CSS Code/unicode for a bullet */ 
  color: green; /* Change the color */ 
  font-weight: bold; /* If you want it to be bold */ 
  display: inline-block; /* Needed to add space between the bullet and the text */  
  width: 1em; /* Also needed for space (tweak if needed) */ 
  margin-left: -1em; /* Also needed for space (tweak if needed) */ 
} 
 
ul .negative{ 
  list-style: none; /* Remove default bullets */ 
} 
 
ul .negative::before { 
  content: "-";  /* Add content: \2022 is the CSS Code/unicode for a bullet */ 
  color: red; /* Change the color */ 
  font-weight: bold; /* If you want it to be bold */ 
  display: inline-block; /* Needed to add space between the bullet and the text */  
  width: 1em; /* Also needed for space (tweak if needed) */ 
  margin-left: -1em; /* Also needed for space (tweak if needed) */ 
}

.fit-content {
  width: fit-content;
}

.no-pointer-events{
  pointer-events: none;
}

.pdf-page {
  box-sizing: border-box;
  width: 100%;
  height: 980px;
  page-break-before: always;
}
.pdf-image-div{

}
.pdf-charts-div{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}
.pdf-chart-div {
  margin-left: -15px;
}
.pdf-chart-div.gauge {
  height: 220px;
}
.pdf-chart-div.b {
  z-index: 50;
}
.gps-pdf-chart {
  margin-left: -15px;
  width: 100%;
  
}

.import-header-buttons {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));
  justify-content: center;
  align-items: center;
  gap: 1em 1em;
}

.data-export-list-item-wrapper {
  width: 100%;
  height: auto;
  position: relative;
  
}

.data-export-list-item {
  background:rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  position: relative;
  display: block;
  width: 100%;
  padding: 10px;
  margin: 3px;
  cursor: pointer;
}

.data-export-list-item:hover {
  background:rgba(0, 0, 0, 0.3);
}

.data-export-list-item::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.student-list-download-button {
  // position: absolute;
  // right: 1em;
}

// .student-list-download-button:hover::after{
//   content: "Download CSV";
//   position: absolute;
//   margin-left: 10px;
//   font-size: x-small;
//   border: 1px solid #009cf0;
//   border-radius: 5px;
//   padding: 2px;
// }

.p-relative {
  position: relative;
}

.impact-chart-label {
  // overflow: hidden;
  display: flex;
  align-items: center;
}
// .impact-chart-label.centered {
//   // overflow: hidden;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }
// .impact-chart-label.start {
//   // overflow: hidden;
//   display: flex;
//   align-items: center;
//   justify-content: start;
// }
// .impact-chart-label.end {
//   // overflow: hidden;
//   display: flex;
//   align-items: center;
//   justify-content: end;
// }

.impact-chart-label > div {
  min-width: 235px;
}

.impact-chart-label > .no-min-width {
  min-width: 0;
}

.impact-chart-labels {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: min-content min-content;
  align-items: center;
  row-gap: 2px;
  column-gap: 5px;
  justify-content: center;
  justify-items: center;
  align-content: center;
}

.chart-label-icon {
  width: 8px;
  height: 8px;
  margin-right: 3px;
}
.impact-label {
  font-size: 10px;
  align-self: center;
}

.chart-label-icon.dot:before {
  content: "\B7 \B7 \B7"
}
.fair.row-one td {background-color: #63BE7B;}
.fair.row-two td {background-color: #75D48E;}
.fair.row-three td {background-color: #A7DAB4;}
.fair.row-four td {background-color: #C1E5C9;}
.fair.row-five td {background-color: #ffffff;}
.fair.row-six td {background-color: #d9d9d9;}
.fair.row-seven td {background-color: #BEBEBF;}
.fair.row-eight td {background-color: #A5A6A6;}
.fair.row-nine td {background-color: #969696;}
.fair.row-ten td {background-color: #FCC8C9;}
.fair.row-eleven td {background-color: #FBACAE;}
.fair.row-twelve td {background-color: #FA8689;}
.fair.row-thirteen td {background-color: #F5686A;}

.fair-check-box {
  position: absolute;
  opacity: 0;

}
.fair-check-box:checked {
  position: absolute;
  opacity: 50;

}

.ilearn-legend {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: .5em;
  // justify-items: center;
  z-index: 1000;
  margin-top: -1.5em;
}

// .ilearn-legend-item:nth-child(n){
//   justify-self: start;
//   justify-content: start;
// }
.ilearn-legend div:first-child{
  justify-self: end;
}

.ilearn-legend-item {
  transform: scale(.75);
}

.border-0 {
  border-style: none;
}
.no-border-collapse {
  border-collapse: separate;
  border-spacing: 0;
}

.max-w-300 {
  max-width: 300px;
}

#target-met-waiting {
  height: 1000px;
  width: 100%;
}

.student-proficiency-table-row.first-row > td {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.student-proficiency-table-row.first-row > td:first-child {border-top-left-radius: 0.25rem !important;}
.student-proficiency-table-row.first-row > td:last-child {border-top-right-radius: 0.25rem !important;}
.student-proficiency-table-row.last-row > td:first-child {border-bottom-left-radius: 0.25rem !important;}
.student-proficiency-table-row.last-row > td:last-child {border-bottom-right-radius: 0.25rem !important;}

.student-proficiency-table-row {
  padding: 0.5rem 1rem;
  text-decoration: none;
  background-color: #fff;
  
}
.student-proficiency-table-row > td:first-child{
  border-left: 1px solid rgba(0, 0, 0, 0.125);
}
.student-proficiency-table-row > td:last-child{
  border-right: 1px solid rgba(0, 0, 0, 0.125);
}

.proficiency-table-card {
  position: relative;
  min-width: 0;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  border-right: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  border-spacing: 0;
}

.proficiency-table-card thead th{
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom-color: rgba(0, 0, 0, 0.125) !important;
}

.budget-table-data {
  min-width: 160px;
}

.budget-table-data.bg-red {
  background-color: rgb(249, 213, 213);
  color: #f7f7f7;
}

.budget-link {
  padding: 4px 8px;
  border-radius: 2px;
}

.pdf-chart-footers {
  margin-top: -10px;
  z-index: 100;
  position: relative;
  height: 0px;
}
.pdf-chart-footers.no-negative-margin {
  margin-top: 10px;
}
.pdf-chart-footer {
  position: absolute;
  height: 0;
}
.pdf-chart-footer.a {
  // padding-right: 20px;
}
div.a {
  z-index: 100;
}
div.b {
  z-index: -100;
}
.pdf-extra-margin {
  margin-left: -45px;
}
.pdf-extra-margin.b {
  margin-left: -55px !important;
}
.pdf-padding-left {
  margin-left: 15px;
  
}
.break-after {
  page-break-after: always;
}
.avoid-inside-break {
  break-inside: avoid;
}
.break-after.auto-break {
  page-break-after: auto;
}

.pointer-auto{
  pointer-events: auto;
}

.reports-div{
  min-height: 300px;
}
.reports-div .card.report-card .card-header span{
  color: #f7f7f7;
  
}

.reports-div .card.report-card:hover .card-header{
  background-color: #009cf0;
}
.reports-div .card.report-card .card-header{
  background-color: #003661;
}

.report-chart{ 
  page-break-after: always;  
  width: 100%; 
} 


.report-chart:last-child { 
  page-break-after: auto; 
} 

.report-card:hover {
  text-decoration: none !important;
  color: #003661;
}

.points-button-active{
  background: #003661;
  color: #f7f7f7;
}
.points-button{
  color: #003661;
  background: #f2f2f2;
}
.points-button:hover{
  background: #868e96;
  color: #f7f7f7;
}

.points-btn{
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.chart-footer-div{ 
  font-size: 1.35em; 
}
.chart-div{
  max-width: 100vw;
  overflow-x: auto;
  overflow-y: visible;
  padding: 1px;
}

.header-list{
  width: 90vw;
  padding: 5px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 300px));
  column-gap: 1em;
  row-gap: .5em;
  overflow-x: hidden;
  list-style-position: inside;
}
.header-list > li{
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.comparison-chart-wrapper{
  display: block;
  text-align: center;
}

@media (min-width: 1375px) {
  .comparison-chart-wrapper {
    display: grid;
    grid-template-columns: 1000px 1fr;
    justify-content: center;
  }
  .comparison-form.no-margin {
    margin-right: 0;
    margin-left: 0;
    
  }
}

.comparison-chart {
  width: 1000px;
  margin-right: auto;
  margin-left: auto;
  height: 800px;
  // flex-basis: 1000px 1;
}

.comparison-form {
  min-width: 350px;
  overflow: hidden;
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
}

table.dataTable.overflow-hidden#needs_attention_table{
  overflow: visible !important;
  overflow-x: auto !important;
  overflow-y: visible !important;
}

.outcome-red{
  background: #f8d8d9;
}
.outcome-yellow{
  background: #fefae3;
}
.outcome-green{
  background: #d3e9d6;
}
.outcome-blue{
  background: #d2e2f0;
}
.table-circle{
  height: 25px; 
  width: 25px; 
  border-radius: 50%; 
  display: inline-block;
}
.pdf-table-circle {
  height: 20px; 
  width: 20px; 
  border-radius: 50%; 
  display: inline-block;

}

.btn-blue {
  color: #f7f7f7;
}

.table-circle-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fixed-height-list-wrapper{
  overflow: auto;
  max-width: 100vw;

}

.fixed-height-list{
  grid-row: 2/ span 1;
  min-width: 100vh;
  column-fill: auto;
  column-count: 3;
}

/* State search results list */

.results-list{
  max-height: 400px;
  position: absolute;
  overflow: auto;
  background-color: rgba(0,54,97, 0.9);
  z-index: 500;
  padding: 1em;
}
.search-result{
  border-radius: 3px;
}

.search-result:hover{
  background-color: #003661;
}

.no-results{
  color: white;
}
.results-list a{
  text-decoration: none;
}
.results-list .search-result *{
  color: #f7f7f7 !important; 
}

// html input[type=text], 
// html input[type=password], 
// html input[type=email], 
// html input[type=date], 
// html input[type=tel], 
// html input[type=number], 
// html button { 
//   height: calc(1.5em + 0.75rem + 2px) !important; 
// } 

i.fas { 
  font-family:FontAwesome!important; 
  font-style: normal;
}

.pointer {
  cursor: pointer;
}

.navbar-toggler {
  border: none;
}
.navbar-collapse ul li a span{
  color: #f7f7f7;
}
.tight-padding{
  padding-top: 0.1em !important;
  padding-bottom: 0.1em !important;
}

.button-link {
  text-decoration: none !important;
  color: #003661;
}

.button-link-color-fix {
  color: inherit;
}
.button-link-color-fix:hover {
  color: inherit;
}


/* Evidence entry box styles */
.file-badges {
  margin-left: 45px;
}
.filename-left-margin {
  margin-left: 10px;
}
.label-margin-fix {
  margin-bottom: 0px
}
.v-align-icon-div {
  height: 100%;
  position: relative;
  padding-left: 6px;
  padding-right: 6px;
  svg {
    margin: 0;
    position: absolute;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
  }
}
.hide {
  display: none;
}

.rubric-form {
  .domains {
    background-color: rgba(0, 0, 0, 0.05);
    .competencies {
      background-color: rgba(0, 0, 0, 0.05);
      .ratings {
        background-color: rgba(0, 0, 0, 0.05);
        .indicators {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    }
  }
}

.observations-form {
  .domains {
    // background-color: rgba(0, 0, 0, 0.05);

    .competencies {
      background-color: rgba(0, 0, 0, 0.05);

      .ratings {
        background-color: rgba(0, 0, 0, 0.05);

        .indicators {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    }
  }
}

.warning-input {
  // background-color: #f35b3f;
  color: #f35b3f;
  border-color: #f35b3f;
  outline-color: #f35b3f;
}

.blinking {

  animation: opacity 2s ease-in-out infinite;
  opacity: 1;
}

@keyframes opacity {
0% {
  opacity: 1;
}

50% {
  opacity: 0
}

100% {
  opacity: 1;
}
}

.tooltip { pointer-events: none; }

.tippy-content {
  color: #f7f7f7;
}

.sticky-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 60px;
  background-color: rgba(233, 233, 233, 1);
  width: 100%;
  z-index: 9998;
}
.footer-fix {
  margin-bottom: 125px;
}

.attached-evidence {
  color: rgba(99, 47, 47, 1);
  font-style: italic;
}
.attached-evidence::after {
  content: " (attached)";
  font-size: 10pt;
}
.attached-element {
  color: rgba(99, 47, 47, 1)
}
.attached-element::after {
  content: " (attached)";
  font-size: 10pt;
}

.private-observation-notes {
  position: fixed;
  bottom: 60px;
  left: 0;
  height: 45px;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  z-index: 3;
}

.text-limiter {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}

.text-limiter-100 {
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}

.text-limiter-200 {
  max-width: 200px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}

trix-editor {
  overflow-y: auto;
  min-height: 100px;
  height: auto !important;
  max-height: 500px !important;
  max-width: 100vw;
}
#trix-toolbar-1 {
  background:#fff;
  overflow-x:scroll;
  white-space: nowrap;
  width:100%;
}

::-webkit-scrollbar { 
    display: none; 
}

.breadcrumb-flex {
  display: flex;
}
.competency {
  white-space: normal;

}

a.dropdown-item.competency.text-primary:active {
  color: #f2f2f2 !important;
}

/* List of students styles */
.page-item.active.paginate_button .page-link{
  color: #fff;
  background-color: #003661;
  border-color: #003661;
}

.page-item.paginate_button .page-link{
  color: #003661;
  background-color: #fff;
  border-color: #dee2e6;
}
.page-item.paginate_button:not(.active) .page-link:hover{
  color: #003661;
  background-color: #dee2e6;
  border-color: #dee2e6;
}
.pagination .current{
  border: 1px solid black;
}

.hover-finger:hover{
  cursor: pointer;
  opacity: .5;
}
.page-entries{
  padding: 1em 0;
}

/*Styling for message alert*/

.alert_wrapper{
  position: fixed;
  background-color: black;
  top: 80px;
  width: 200px;
  height: 150px;
  opacity: 50%;
  padding: 1em;
  z-index: 1000;
  right: -205px;
  transition: transform 1s;
}
#message-alert-box{
  position: sticky;
}
.alert_box_shown{
  transform: translateX(-205px);
  
}

.budget-select{
  position: relative;
  color: #003661;
}
.budget-select:hover{
  position: relative;
  background-color: rgba($color: #dee2e6, $alpha: 1);
  color: #0073e8;
}

#navbtn{
  margin-right: 10em;
}

.performance-card-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 400px));
  justify-content: center;
  align-items: center;
  gap: 1em 1em;
}

.performance-card{
  border-radius: 5px;
  background: #eceef1;
  min-height: 205px;
  display: flex;
  flex-direction: column;
  border: 1px solid #003661;
  border-top: 4px solid #003661;
  border-bottom: 4px solid #003661;
}

/************  Messages  **************/
.channel-container{
  padding: 0 2em;
}
.channel-link{
  color: #fff;
}
.message-container{
  min-height: 500px;
  max-height: 500px;
  overflow: auto;
  scroll-behavior: smooth;
}
.message-time{
  font-size: 75%;
}
.channel-header{
  height: 60px;
}
#add_user_to_channel{
  left: 0;
  z-index: 100;
}
.message-list * {
  overflow-anchor: none;
}

.message{
  overflow-anchor: none;
}
.message-list{
  min-height: 501px;
  overflow-anchor: none;
}
.no-list-style {
  list-style-type: none;
}
.message-body{
  border: 1px solid black;
  margin: 2em;
}
.indicator-light{
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.new-message-container{
  color: #fff;
  border: 1px solid #003661;
  border-radius: 5px;
}

#anchor {
  overflow-anchor: auto;
  height: 1px;
  scroll-behavior: smooth;
}

/***************************************/

/************* Tracker section *************/

.tracker-table{
  max-width: calc(100vw - 2em);
}

.tracker-table-card{
  max-width: calc(100vw - 50px);
}

.tracker-list-item:hover{
  background: #003661;
  color: #f7f7f7;
}

.pagination.pagination-center{
  justify-content: center !important;
}

.dt-bootstrap5 .btn.btn-primary, .btn.btn-success, a.btn.btn-secondary, a.btn.btn-danger{
  color: #f7f7f7;
}

table.dataTable td.dt-control {
  text-align: center;
  cursor: pointer;
}

table.dataTable tr.dt-hasChild.shown td.dt-control:before {
  content: "-";
  background-color: #d33333;
}

.dt-control::before {
  height: 1em;
  width: 1em;
  margin-top: -9px;
  display: inline-block;
  color: white;
  border: 0.15em solid white;
  border-radius: 1em;
  box-shadow: 0 0 0.2em #444;
  box-sizing: content-box;
  text-align: center;
  text-indent: 0 !important;
  font-family: "Courier New",Courier,monospace;
  line-height: 1em;
  content: "+";
  background-color: #31b131;
}

.usage-table {
  padding-right: 8px;
}

.child-row.usage-table {
  background-color: rgba(0,0,0,.2);
}
.child-row.usage-table td, .usage-table th {
  border-top: 1px solid gray;
  border-left: 1px solid gray;
  border-left-width: 1px !important; 
  min-width: 150px;
}

.usage-table-child-row {
  border-top: 1px solid #003661;
  border-bottom: 1px solid #003661;
}

.btn-outline-success:hover{
  color: #fff;
}
.btn-outline-secondary:hover{
  color: #fff;
}

/***************************************/

.thick-border{
  outline: thin solid #999999;
}

.will_paginate {
  background: white;
  cursor: default;
  /* self-clearing method: */ }
  .will_paginate a, .will_paginate span, .will_paginate em {
    padding: 0.2em 0.5em;
    display: block;
    float: left;
    margin-right: 1px; }
  .will_paginate .disabled {
    color: #999999;
    border: 1px solid #dddddd; }
  .will_paginate .current {
    font-style: normal;
    font-weight: bold;
    background: #2e6ab1;
    color: white;
    border: 1px solid #2e6ab1; }
  .will_paginate a {
    text-decoration: none;
    color: #105cb6;
    border: 1px solid #9aafe5; }
    .will_paginate a:hover, .will_paginate a:focus {
      color: #000033;
      border-color: #000033; }
  .will_paginate .page_info {
    background: #2e6ab1;
    color: white;
    padding: 0.4em 0.6em;
    width: 22em;
    margin-bottom: 0.3em;
    text-align: center; }
    .will_paginate .page_info b {
      color: #000033;
      background: #6aa6ed;
      padding: 0.1em 0.25em; }
  .will_paginate:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden; }
  * html .will_paginate {
    height: 1%; }
  *:first-child + html .digg_pagination {
    overflow: hidden; }
.my-fa-button{
  font-size: 175%;

}

.text-success{
  color: #5cb85c !important;
}

.btn-success{
  background-color: #5cb85c !important;
  color: white;
}
.btn-success:hover{
  color: white !important;
  background-color: #19692c !important;
}
.my-fa-button.text-success:hover{
  color: #19692c !important;
  cursor: pointer !important;
}

.my-fa-button.text-danger:hover{
  color: #c82333 !important;
  cursor: pointer;
}
.no-borders{
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
  border-bottom-style: none;
}

.sorting_asc, .sorting_desc{
  color: #f7f7f7;
  background-color: #003661 !important;
}

.sorting:not(.sorting_asc):not(.sorting_desc){
  color: #003661;
}
th.sorting:hover{
  background: #868e96;
  color: #f7f7f7;
}

.table-link:hover{
  background: #003661;
  color: #f7f7f7;
}
.table.table-striped.m-0.no-margin{
  margin: 0 !important;
}
/* Tabs and title ************************ */
.title-button-div{
  height: 100%;
}
.tab-div{
  display: flex;
  flex-wrap: no-wrap;
}

.single-row-tab-div{
  background: rgb(242,242,242);
  background: linear-gradient(0deg, rgba(242,242,242,1) 40%, rgba(255,255,255,1) 70%);
  display: flex;
  flex-wrap: no-wrap;
  border-bottom: 1px solid #05d9ff;
}
.single-row-tab-div .single-row-tab.active .tab-content{
  color: #f7f7f7;
}
.single-row-tab-div .single-row-tab:not(.active):hover .tab-content{
  color: #009cf0;
}

.single-row-tab.active {
  color: white !important;
  background-color: #003661;
}
.single-row-tab.active a{
  color: white !important;
}

.single-row-tab:not(.active):hover{
  background-color: #dee2e6;
}

.tab.active a{
  color: #f7f7f7;
}
.tab:not(.active):hover{
  color: #009cf0;
  background-color: #dee2e6;
}
.tab-option:not(.active):hover{
  background-color: #dee2e6;
}

.single-row-tab{
  color: #003661;
  border-radius: 1em 1em 0 0;
  flex-grow: 1;
  padding: 2px 4px;
}

.navy-background{
  background-color: #003661;
}
.tab-option-div{
  border-top: 1px solid #05d9ff;
  display: flex;
  flex-wrap: nowrap;
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,1) 40%, rgba(242,242,242,1) 70%);
  max-width: 100vw;
}
.tab-option-div.month{
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
a{
  text-decoration: none !important;
  color: #003661;
}
span, p, h1, h2, h3, h4, h5, h6, label, small, div, option, input{
  color: #003661;

}
.sorting_desc.small:not(.sorting_asc):not(.sorting_desc), .sorting_asc.small:not(.sorting_asc):not(.sorting_desc) {
  color: #f7f7f7;
}
a:not(.active, .btn, .dropdown-toggle, .sidebar-link, .scroll-link):hover{
  color: #0073e8;
}

.tab{
  color: #003661;
  border-radius: 1em 1em 0 0;
  flex-grow: 1;
  text-decoration: none;
  padding: 2px 4px;
}

a.tab-option{
  text-decoration: none;
}

a.tab-content{
  width: 100%;
  color: #003661;
  text-decoration: none;
}
a.tab-option.active span.tab-content{
  color: #f7f7f7; 
}
a.tab-option:not(.active):hover span.tab-content{
  color: #0073e8;
}
a.tab:not(.active):hover span.tab-content{
  color: #0073e8;
}
@media (max-width: 800px){
  .tab-option-div.month{
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (max-width: 930px){
  
  .tab-option-div.month .tab-content{
    font-size: 75%;
  }
}
@media (max-width: 470px){
  
  .tab-option-div.month .tab-content{
    font-size: 50%;
  }
}

.tab.active{
  color: white !important;
  background-color: #003661;
}

.tab.active .tab-content{
  color: white;
}
.background-div{
  height: 42px;
}
.tab-option{
  color: #003661;
  flex-grow: 1;
  padding: 0 10px;
}

.tab-option.active{
  color: white !important;
  z-index: 100;
  background-color: #009cf0;
}

a.tab-content:active{
  color: white;
}

.custom-radio-button{
  width: 20px;
  height: 20px;
  border: 1px solid #003661;
  padding: 10px;
  border-radius: 50%;
  margin-right: 10px;
}

.btn-custom-primary{
  background-color: #003661;
  color: #fff;
  border-color: #003661;
}

.custom-radio-button-inner{
  background-color: #0073e8;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
}

.comparison-template-wrapper {
  display: flex;
  display: -webkit-flex; 
  justify-content: center;
  -webkit-justify-content: center;
  width: 100%;
  text-align: center;
}

.comparison-template-wrapper .comparison-template {
  margin-left: 50%;
  margin-right: auto;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  max-width: 350px;
}

.comparison-template {
  padding: 1.5em;
  border: 1px solid gray;
  border-radius: 5px;
  margin-top: 1em;
  margin-bottom: 1em;
  justify-self: center;
  -webkit-justify-self: center;
}

.comparison-table {
}

.comparison-row {
  display: flex;
  display: -webkit-flex; 
  justify-content: space-between;
  -webkit-justify-content: space-between;
  position: relative;
  text-align: initial;
}

.comparison-table-peer-group-row {
  background-color: #ffb83a;
  
}

.comparison-table-owner-row {
  background-color: #1f6ecc;
  
}
.comparison-table-owner-row *{
  color: #f7f7f7 !important;
  
}

.comparison-caption {
  text-align: center;
}

.comparison-row span:nth-child(2) {
  position: absolute;
  right: 0;
  white-space: nowrap;
}

/* **************************************** */

.visible{
  visibility: visible;
}
.invisible{
  visibility: hidden;
}

.display{
  display: block;
}

.do-not-display{
  display: none;
}

.orange-button{
  border: 1px solid #ff7a00;
  border-radius: 10px;
  background: transparent;
  color: #ff7a00;
  padding: 5px 12px;
  text-decoration: none !important;
  vertical-align: middle;
  line-height: inherit;
  display: inline-block;
}

.orange-button:hover {
  text-decoration: none !important;
  color: #003661;
  border-color: #003661;
}
.orange-button.no-hover:hover{
  border: 1px solid #ff7a00;
  background: transparent;
  color: #ff7a00;
}

.orange-button-selected {
  border: 1px solid #ff7a00;
  border-radius: 10px;
  background: #ff7a00;
  color: #ffffff !important;
  padding: 5px 12px;
  text-decoration: none !important;
}

.orange-button-selected:hover {
  border: 1px solid #ff7a00;
  border-radius: 10px;
  background: #ff7a00;
  color: #ffffff !important;
  padding: 5px 12px;
  text-decoration: none !important;
}

.employee-fixed-height-table{
  max-height: 100vh;
  overflow-y: auto;
}

.growth-selector:hover{
  color: #0073e8;
}

.growth-event-table{
  max-height: 400px;
  overflow: auto;
}

.growth-event-checkbox{
  position: relative;
}
.growth-event-checkbox:before{
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  background: transparent;
  opacity: 50;
  top: -50%;
  left: -50%;
  // transform: translate(50%, 50%);
}

.subgroup-selector-wrapper {
  max-height: 1000px;
  transition: max-height .5s ease-in-out, visibility .5s ease-in-out;
  visibility: visible;
  opacity: 100;
}

.subgroup-selector-wrapper.hidden {
  max-height: 0;
  visibility: hidden;
}

.empty-gauge{
  height: 250px;
}

.gauge-row{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  justify-items: center;
}

/***** Wrapper *****/

.wrapper { display: block}

/***** Sidebar *****/

.sidebar {
  width: 400px; position: fixed; top: 0; bottom: 0; right: -405px; z-index: 999; 
  background: #333; color: #fff; transition: all .3s; box-shadow: 3px 3px 3px rgba(51, 51, 51, 0.5);
  text-align: left; visibility: hidden; min-height: 400px;
  max-height: 100vh;
  overflow: auto;
}
.menu-elements{
  min-height: 0;
  overflow: visible;
  
  
}

.sidebar-header-link{
  background: #444;
  border-radius: 4px;
  transition: all .3s;
  min-height: 0;
}
.sidebar-footer{
  z-index: -10;
  min-height: 0;
}

.dropdown-menu{
  z-index: 1000;
}

.dropdown-limit-height{
  max-height: 50vh;
  overflow: auto;
  max-width: 100vw;
}

.dropdown-limit-width{
  max-width: 300px;
  overflow: auto;
}

.mw-200 {
  min-width: 200px;
}

.ts-control input:focus::placeholder {
  color: transparent;
}

.admin-dashboard-dropdown {
  background: #f2f2f2;
}

.assessment-header-dropdown{
  min-width: 100%;
}
a.muted {
  color:rgb(165, 165, 165);
}
.muted {
  color:rgb(165, 165, 165);
}

a.background-primary.domain:hover{
  background-color: #0059a1;
}
a.background-primary.domain{
  background-color: #003661;
}

a.background-blue.domain:hover{
  background-color: #05d9ff;
}
a.background-blue.domain{
  background-color: #009cf0;
}
input.btn.btn-primary:hover{
  background-color: #009cf0;
}
a.btn.btn-primary:hover{
  background-color: #009cf0;
}

a.background-primary:hover{
  background-color: #009cf0;
}
button.btn.btn-primary:hover{
  background-color: #009cf0;
}

/***********  Custom Media Queries  ************/

@media screen and (max-height: 450px){
  .menu-elements{
    height: 35%;
  }
}


@media screen and (max-width: 576px){
  .radio-div{
    margin-bottom: 1em;
  }
  .tab-content{
    font-size: 75%;
  }
}

@media screen and (max-width: 490px){
  .sidebar{
    width: 100vw;
    left: 100%;
  }
  .sidebar.active{
    left: 0;
  }
  .radio-div{
    transform: scale(.75);
  }
}

@media screen and (max-width: 415px){
  .tab-content{
  font-size: 60%;
  }
  .tab-option{
    padding: 5px;
  }
  .single-row-tab{
    padding: 2px 2px;
  }
  .custom-radio-button > span{
    font-size: 80%;
  }
}

@media screen and (max-width: 360px){
  .tab-option.tab-content{
    font-size: 40%;
  }
  .tab.tab-content{
    font-size: 60%;
  }
}


.dropdown-toggle{
  width: 100%;
}
.sidebar.active { right: 0; visibility: visible}

.dismiss {
  width: 35px; height: 35px; position: absolute; top: 10px; right: 10px; transition: all .3s; 
  background: #444; border-radius: 4px; text-align: center; line-height: 35px; cursor: pointer;
  color: #f7f7f7;
}

.dismiss:hover, .dismiss:focus, .sidebar-header-link:hover, .sidebar-header-link:focus { background: #555; color: #fff; }

.sidebar .logo { padding: 40px 20px; border-bottom: 1px solid #444; transition: all .3s; }

.sidebar .logo a {
  display: inline-block;
  width: 172px;
  height: 34px;
  border: 0;
  text-indent: -999999px;
}

.sidebar ul.menu-elements { padding: 10px 0; border-bottom: 1px solid #444; transition: all .3s; }

.sidebar ul li a {
  display: block; padding: 10px 20px;
  border: 0; color: #fff;
  text-decoration: none;
}
.sidebar ul li a:hover,
.sidebar ul li a:focus,
.sidebar ul li.active > a:hover,
.sidebar ul li.active > a:focus { outline: 0; background: #555; color: #fff; }

.sidebar ul li a i { margin-right: 5px; }

.sidebar ul ul a { background: #444; padding-left: 30px; font-size: 14px; }

.sidebar ul ul li.active > a { background: #555; }

.sidebar a[data-toggle="collapse"] {
    position: relative;
}

.sidebar .dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

/* sidebar light */

.sidebar.light { background: #fff; color: #888; }

.sidebar.light .dismiss { background: #ddd; color: #888; }

.sidebar.light .dismiss:hover, 
.sidebar.light .dismiss:focus { background: #ccc; color: #888; }

.sidebar.light .logo { border-color: #eee; }

.sidebar.light ul.menu-elements { border-color: #eee; }

.sidebar.light ul li a { color: #888; }

.sidebar.light ul li a:hover,
.sidebar.light ul li a:focus,
.sidebar.light ul li.active > a:hover,
.sidebar.light ul li.active > a:focus { background: #ccc; color: #888; }

.sidebar.light ul li.active > a, .sidebar.light a[aria-expanded="true"] {
  background: #ddd;
  color: #888;
}

.sidebar.light ul ul a { background: #ddd; }

.sidebar.light ul ul li.active > a { background: #ccc; }

.sidebar.light a.btn-customized-3 { background: #ddd; color: #888; }

.sidebar.light a.btn-customized-3:hover, 
.sidebar.light a.btn-customized-3:active, 
.sidebar.light a.btn-customized-3:focus, 
.sidebar.light a.btn-customized-3:active:focus, 
.sidebar.light a.btn-customized-3.active:focus,
.sidebar.light a.btn-customized-3.btn.btn-primary:not(:disabled):not(.disabled):active,
.sidebar.light a.btn-customized-3.btn.btn-primary:not(:disabled):not(.disabled):active:focus {
  background: #ccc; color: #888;
}

.sidebar.light a.btn-customized-4.btn-customized-dark { background: #555; }
.sidebar.light a.btn-customized-4.btn-customized-light { background: #eee; }

.sidebar.light a.btn-customized-4.btn-customized-dark:hover, 
.sidebar.light a.btn-customized-4.btn-customized-dark:active, 
.sidebar.light a.btn-customized-4.btn-customized-dark:focus, 
.sidebar.light a.btn-customized-4.btn-customized-dark:active:focus, 
.sidebar.light a.btn-customized-4.btn-customized-dark.active:focus,
.sidebar.light a.btn-customized-4.btn-customized-dark.btn.btn-primary:not(:disabled):not(.disabled):active,
.sidebar.light a.btn-customized-4.btn-customized-dark.btn.btn-primary:not(:disabled):not(.disabled):active:focus {
  background: #ccc; color: #fff;
}

.sidebar.light a.btn-customized-4.btn-customized-light:hover, 
.sidebar.light a.btn-customized-4.btn-customized-light:active, 
.sidebar.light a.btn-customized-4.btn-customized-light:focus, 
.sidebar.light a.btn-customized-4.btn-customized-light:active:focus, 
.sidebar.light a.btn-customized-4.btn-customized-light.active:focus,
.sidebar.light a.btn-customized-4.btn-customized-light.btn.btn-primary:not(:disabled):not(.disabled):active,
.sidebar.light a.btn-customized-4.btn-customized-light.btn.btn-primary:not(:disabled):not(.disabled):active:focus {
  background: #ccc; color: #fff;
}


/***** Dark overlay *****/

.overlay {
    display: none; position: fixed; width: 100vw; height: 100vh; 
    background: rgba(51, 51, 51, 0.7); z-index: 998; opacity: 0; transition: all .5s ease-in-out;
}

.overlay.active { display: block; opacity: 1; }

/***** Content *****/

.content { width: 100%; transition: all 0.3s;}
// .financial-full-height-row{ min-height: calc(100vh - 200px); }
.open-menu { z-index: 997; box-shadow: 3px 3px 3px rgba(51, 51, 51, 0.2); }

.open-menu-alternate { 
  position: fixed; 
  top: 110px; 
  left: -30px; 
  z-index: 997; 
  box-shadow: 3px 3px 3px rgba(51, 51, 51, 0.2); 
  vertical-align:top;
  transform:rotate(7deg);
  -ms-transform:rotate(90deg); /* IE 9 */
  -moz-transform:rotate(90deg); /* Firefox */
  -webkit-transform:rotate(90deg); /* Safari and Chrome */
  -o-transform:rotate(90deg); }

.open-menu.btn-customized,
.open-menu.btn-customized:hover, 
.open-menu.btn-customized:active, 
.open-menu.btn-customized:focus, 
.open-menu.btn-customized:active:focus, 
.open-menu.btn-customized.active:focus,
.open-menu.btn-customized.btn.btn-primary:not(:disabled):not(.disabled):active,
.open-menu.btn-customized.btn.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 3px 3px 3px rgba(51, 51, 51, 0.2);
}


/***** Buttons *****/

a.btn-customized {
  margin-left: 5px; margin-right: 5px; padding: .75rem 1.5rem; 
  background: #f35b3f; border: 0; border-radius: 4px; 
  font-size: 16px; font-weight: 300; color: #fff; box-shadow: none;
}

a.btn-customized:hover, 
a.btn-customized:active, 
a.btn-customized:focus, 
a.btn-customized:active:focus, 
a.btn-customized.active:focus,
a.btn-customized.btn.btn-primary:not(:disabled):not(.disabled):active,
a.btn-customized.btn.btn-primary:not(:disabled):not(.disabled):active:focus {
  outline: 0; background: #cd533d; background: rgba(243, 91, 63, 0.7); border: 0; color: #fff; box-shadow: none;
}
.dt-bootstrap5 a, td{
  color: #003661;
} 

.dt-bootstrap4 .pagination .paginate_button.active{
  color: white;
  background-color: #003661;
}
.select2-container--bootstrap.select2-container{
  width: 100%;
}
tr td{
  display: table-cell;
  vertical-align: middle;
}

tr th.sorting_asc, th.sorting_desc {
  color: #fff;
}

th:not(.sorting_asc):not(.sorting_desc){
  color: #003661;
}

td .items.ts-input{
  height: 100%;
  transform: translateY(3px);
}

.pagination{
  justify-content: center !important;
}



/************  Table hover effects ***************/
.user-table-row, .student-table-row, .win-group-table-row {
  background-color:rgba(0, 0, 0, 0.0);
  pointer-events: all;
}

.user-table-row:hover, .student-table-row:hover, .win-group-table-row:hover {
  background-color:rgba(0, 0, 0, 0.1);
  cursor: pointer;

}


.full-size a:after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  pointer-events: auto;
}

.full-size.bg-table-success{
  background-color:rgba(92, 184,92, 1);
  pointer-events: none;
}
.full-size.bg-table-success:hover{
  background-color:rgb(57, 133, 73);
}

.full-size.bg-table-warning{
  background-color:rgb(240, 173, 78);
  pointer-events: none;
}
.full-size.bg-table-warning:hover{
  background-color:rgb(161, 117, 55);
}

.full-size.bg-table-danger{
  background-color:rgba(217, 83, 79, 1);
  pointer-events: none;
}
.full-size.bg-table-danger:hover{
  background-color:rgb(145, 56, 53);
}

.full-size.bg-table-primary{
  background-color: #003661;
  pointer-events: none;
  color: #f7f7f7 !important;
}
.full-size.bg-table-primary:hover{
  background-color:#0059a1;
  color: #f7f7f7 !important;
}

.full-size.bg-table-secondary {
  background-color:rgba(114, 116, 117, .7);
  pointer-events: none;
}
.full-size.bg-table-secondary:hover {
  background-color:rgba(114, 116, 117, 1);
}

.full-size.bg-table-transparent {
  background-color:rgba(0, 0, 0, 0.0);
  pointer-events: none;
}

.full-size.bg-table-transparent:hover {
  background-color:rgba(0, 0, 0, 0.1);

}

/************  End table hover effects ***************/

td {
  position: relative;
  // overflow: hidden;
}


.pagination .paginate-button.active:hover{
  color: #003661;
  background-color: white;
}

.code {
  margin: 25px;
  padding: 25px;
  background-color: rgba(0, 0, 0, 0.05);
}

.get-in-line {
  display: inline;
}

.map-report-card-link:hover .card{
  
  opacity: .75;
  // position: relative
}
// .map-report-card-link:hover:after{
//   content: "";
//   width: 100%;
//   height: 100%;
//   opacity: .5;
// }

.student-card {
  min-width: 350px;
}

/**************** Salaries ******************/
// .salary-table {
//   display: none;
// }

.salary-table.active {
  display: flex;
}

.salary-selector.active {
  background-color: #009cf0;
  border-color: #009cf0;
}

#salaries_data_table_wrapper {
  width: 100%;
}

.salary-corporations-list {
  grid-template-columns: repeat(3, 1fr); 
  column-gap: 4em; 
  display: grid;

}

@media screen and (max-width: 768px) {
  .salary-corporations-list {
    grid-template-columns: repeat(2, 1fr); 
    column-gap: 2em; 
    display: grid;
  
  }
}



/**************** Observations and Evaluation s******************/

.reviewed-observation{
  background: green;
  color: #f7f7f7;
}

.completed-observation{
  background: yellow;
}

.overdue-observation{
  background: red;
  color: #f7f7f7;
}
.accordion-button.collapsed{

  .evaluation-domain-hover:hover{
    background: #f2f2f2;
  }
}

.evaluation-score-input{
  width: 100px;
  
}

.background-filter{
  box-shadow: 10px 10px 10px 10px rgba(0,0,0,.3);
}
.no-pointer-events{
  pointer-events: none;
}


.saved{
  color: rgba(92,184,92,1);
  animation-duration: .5s;
  animation-name: glow;
}
@keyframes greenglow {
  from{
    color:rgba(92,184,92,1);
  }
  to{
    color:rgba(92,184,92,0);
  }
}

.saving{
  animation-duration: .5s;
  animation-name: glow;
}
@keyframes glow {
  from{
    color:rgba(0, 0, 0, 1);
  }
  to{
    color:rgba(0, 0, 0, 0);
  }
}

.unsaved{
  color:rgba(243, 91, 63, 1);
  animation-duration: .5s;
  animation-name: redglow;
}
@keyframes redglow {
  from{
    color:rgba(243, 91, 63, 1)
  }
  to{
    color:rgba(243, 91, 63, 0)
  }
}

/************************************************/

/**************** Peer Groups *******************/

.peer-group-link{
  color: #003661;
}
.peer-group-link:hover{
  color: #f7f7f7;
  background-color: #868e96;
}
.peer-group-link-div:hover{
  border: 1px solid #868e96;
}
.peer-group-card{
  min-width: 210px;
  max-width: 210px;
}
/************************************************/

/**************** Financial *******************/
  
.financial-search-form{
  z-index: 10;
  position: relative;
}
/************************************************/


.no-pointer{
  pointer-events: none;
}
.auto-pointer{
  pointer-events: auto;
}

.flex-1{
  flex: 1;
}

.flashing{
  animation: flashing .5s infinite alternate;
}

@keyframes flashing{
  from{opacity: 100%};
  to{opacity: 75%};
  
}
